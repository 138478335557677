var render = function () {
  var _vm$report, _vm$report2, _vm$report3, _vm$report4, _vm$report5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "analytics-card",
    on: {
      "click": function click($event) {
        return _vm.$emit('showReport', _vm.report);
      }
    }
  }, [_c('div', {
    staticClass: "analytics-card__inner"
  }, [_c('div', {
    staticClass: "analytics-card__info"
  }, [_c('div', {
    staticClass: "analytics-card__info-flex"
  }, [_c('GenproxBadge', {
    attrs: {
      "circle": true,
      "outline": true,
      "variant": ((_vm$report = _vm.report) === null || _vm$report === void 0 ? void 0 : _vm$report.source) === 'db-fundequate' ? 'gn-primary' : 'orange',
      "text": ((_vm$report2 = _vm.report) === null || _vm$report2 === void 0 ? void 0 : _vm$report2.source) === 'db-fundequate' ? 'General' : 'Optima'
    }
  })], 1), _c('p', {
    staticClass: "analytics-card__name"
  }, [_vm._v(_vm._s((_vm$report3 = _vm.report) === null || _vm$report3 === void 0 ? void 0 : _vm$report3.name))]), (_vm$report4 = _vm.report) !== null && _vm$report4 !== void 0 && _vm$report4.description ? _c('p', {
    staticClass: "analytics-card__description",
    domProps: {
      "innerHTML": _vm._s(((_vm$report5 = _vm.report) === null || _vm$report5 === void 0 ? void 0 : _vm$report5.description) || '')
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "analytics-card__footer"
  }, [_c('div', {
    staticClass: "analytics-card__actions"
  }, [_c('GenproxButton', [_vm._v("Show report")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }