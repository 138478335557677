var render = function () {
  var _vm$$route, _vm$reportModal;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: (_vm$$route = _vm.$route) === null || _vm$$route === void 0 ? void 0 : _vm$$route.fullPath,
    staticClass: "analytics"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('GenproxPageHeading', {
    attrs: {
      "breadcrumbsTitle": _vm.breadcrumbsTitle,
      "title": "Analytics",
      "description": "Flexible access to in-depth company analytics, allowing for customized insights based on selected data sets."
    }
  }), !_vm.selectedReport ? _c('div', {
    staticClass: "analytics__container"
  }, _vm._l(_vm.reportList, function (report) {
    return _c('div', {
      key: report === null || report === void 0 ? void 0 : report.id,
      staticClass: "analytics__box"
    }, [_c('AnalyticsCard', {
      attrs: {
        "report": report
      },
      on: {
        "showReport": _vm.handleShowReport
      }
    })], 1);
  }), 0) : _vm._e(), _vm.selectedReport ? _c('pivot-table', {
    attrs: {
      "schema": _vm.pivotSchema,
      "reportName": _vm.selectedReport,
      "tableData": _vm.tableData
    },
    on: {
      "goBack": _vm.goBackHandler
    }
  }) : _vm._e(), (_vm$reportModal = _vm.reportModal) !== null && _vm$reportModal !== void 0 && _vm$reportModal.show ? _c('GenproxModal', {
    attrs: {
      "header": "Choose period",
      "cancelText": "Cancel",
      "confirmText": "Continue",
      "smallerActionsPadding": true
    },
    on: {
      "cancel": _vm.closeReportModal,
      "close": _vm.closeReportModal,
      "confirm": _vm.confirmReportModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-select', {
          attrs: {
            "legacyStyling": false,
            "label": "Year",
            "placeholder": "YYYY",
            "allow-empty": false,
            "options": _vm.reportYearOptions
          },
          model: {
            value: _vm.reportModal.year,
            callback: function callback($$v) {
              _vm.$set(_vm.reportModal, "year", $$v);
            },
            expression: "reportModal.year"
          }
        })];
      },
      proxy: true
    }], null, false, 4144599763)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }