




















import GenproxButton from '@/components/buttons/GenproxButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import GenproxBadge from '@/components/layout/GenproxBadge.vue';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component({
  components: { GenproxBadge, SygniRoundedButton, GenproxButton },
})
export default class AnalyticsCard extends Vue {
  @Prop({ default: null }) report: any
}
